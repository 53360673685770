
import './App.css';
import Invoice from './Invoice';

function App() {
  return (
    <div className="App">
     <Invoice />
    </div>
  );
}

export default App;
